.app__footer{
    color: white;
    width: 100%;
    display:flex-end;
    align-items: center;
    background: rgba(33,37,41);

    position: fixed;
    bottom: 0;
    
    
    right: 0;
    left: 0;
 

 }

 .copyright{
     padding-bottom: 0.5rem;
     padding-top: 0.5rem;
    
     float: right;
    
   
 }

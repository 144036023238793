.Season{
padding-top: 20px;

}

.inner_season{
    /* display: flex; */
    /* flex-wrap: wrap;
    justify-content: center; */
    
}

.season_layout{
    /* display: flex; */
}

.season_image{
    padding-bottom: 20px;
  
    


}

.season_data{
  /* border: solid; */
  width: auto;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  font: bold;
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;


   
}
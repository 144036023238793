.Mentor_Villains{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2rem;
}

.inner_Mentor_Villains{
    display: flex;
    padding: 10px;
}
.Main{
    padding-top: 10px;
    padding-bottom: 10px;
}

.Rangers{
    
    /* align-content: center; */
      
    /* display: flex;
    flex-flow: row wrap;
    position: relative; */
   
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    


}

.ranger{
   
    padding: 10px;
    padding-bottom: 10px;
    display: flex;
    
 
 
}